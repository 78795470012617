@font-face {
    font-family: "Bliss";
    font-display: swap;
    src: url("./xlight.woff");
    src: 
        url("./xlight.woff2") format("woff2"),
        url("./xlight.woff") format("woff");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Bliss";
    font-display: swap;
    src: url("./light.woff");
    src: 
        url("./light.woff2") format("woff2"),
        url("./light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Bliss";
    font-display: swap;
    src: url("./regular.woff");
    src: 
        url("./regular.woff2") format("woff2"),
        url("./regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Bliss";
    font-display: swap;
    src: url("./bold.woff");
    src: 
        url("./bold.woff2") format("woff2"),
        url("./bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Bliss";
    font-display: swap;
    src: url("./xbold.woff");
    src: 
        url("./xbold.woff2") format("woff2"),
        url("./xbold.woff") format("woff");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Bliss";
    font-display: swap;
    src: url("./heavy.woff");
    src: 
        url("./heavy.woff2") format("woff2"),
        url("./heavy.woff") format("woff");
    font-weight: 900;
    font-style: normal;
}
