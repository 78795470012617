@font-face {
    font-family: "Bilo";
    font-display: swap;
    src: url("./xlight.woff");
    src: 
        url("./xlight.woff2") format("woff2"),
        url("./xlight.woff") format("woff");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Bilo";
    font-display: swap;
    src: url("./light.woff");
    src: 
        url("./light.woff2") format("woff"),
        url("./light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Bilo";
    font-display: swap;
    src: url("./regular.woff");
    src: 
        url("./regular.woff2") format("woff2"),
        url("./regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Bilo";
    font-display: swap;
    src: url("./medium.woff");
    src: 
        url("./medium.woff2") format("woff2"),
        url("./medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Bilo";
    font-display: swap;
    src: url("./black-italic.woff");
    src: 
        url("./black-italic.woff2") format("woff2"),
        url("./black-italic.woff") format("woff");
    font-weight: 900;
    font-style: italic;
}